import React, { useEffect } from "react"

import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "components/Select"
import useShelves from "hooks/useShelves"

interface ShelfSelectProps {
  shelfId?: string
  onSelect?: (shelfId: string) => void
}

const ShelfSelect: React.FC<ShelfSelectProps> = ({ shelfId, onSelect }) => {
  const { data: shelves } = useShelves()

  useEffect(() => {
    if (!!onSelect) {
      onSelect(shelves?.[0].id.toString() ?? "")
    }
  }, [shelves, onSelect])

  return (
    <Select onValueChange={onSelect} value={shelfId}>
      <SelectTrigger className='w-[180px]'>
        <SelectValue placeholder='Select a shelf' />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Shelves</SelectLabel>
          {shelves?.map(shelf => (
            <SelectItem key={shelf.id} value={shelf.id.toString()}>
              {shelf.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default ShelfSelect
